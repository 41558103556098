import './All.css';
import './WhiteInput.css';

import Header from '../Header';
import Footer from '../Footer';
import OrderForm from '../OrderForm';

import * as React from 'react';
import { useLocation } from "react-router-dom";
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import StandardImageList from '../util/StandardImageList';

import { primaryButtonSX, secondaryButtonSX } from "../util/sxButton";

import CableTrayAccessoriesImage from '../img/cable-tray-accessories.jpg';
import CeilingPanelsImage from '../img/ceiling-panels.jpg';
import PartitionWallImage from '../img/partition-wall.jpg';

import ProfileLAngleImage from '../img/ceiling-l-angle.jpg';
import ProfileUChannelImage from '../img/ceiling-u-channel.jpg';
import ProfileOmegaImage from '../img/omega.png';
import ProfileTrackImage from '../img/metal-track.jpg';
import ProfileStudImage from '../img/metal-stud-2.jpg';

import MainContainerImage from '../img/pipes.jpg';

import CableTrayImage from '../img/cable-tray.jpg';
import CableLadderImage from '../img/cable-ladder.jpg';
import MetalDoorExteriorImage from '../img/metal-door-exterior.jpg';
import DixonShelfImage from '../img/dixon-shelf.jpg';
import BreakerBoxImage from '../img/breaker-box.png';
import FloorBoxImage from '../img/floor-box.png';
import FireBoxImage from '../img/fire-box.png';
import SheetMetalImage from '../img/sheet-metal.jpg';


import CableTrayAccessoriesImage_WebP from '../img/cable-tray-accessories.webp';
import CeilingPanelsImage_WebP from '../img/ceiling-panels.webp';
import PartitionWallImage_WebP from '../img/partition-wall.webp';

import ProfileLAngleImage_WebP from '../img/ceiling-l-angle.webp';
import ProfileUChannelImage_WebP from '../img/ceiling-u-channel.webp';
import ProfileOmegaImage_WebP from '../img/omega.webp';
import ProfileTrackImage_WebP from '../img/metal-track.webp';
import ProfileStudImage_WebP from '../img/metal-stud-2.webp';

import MainContainerImage_WebP from '../img/pipes.webp';

import CableTrayImage_WebP from '../img/cable-tray.webp';
import CableLadderImage_WebP from '../img/cable-ladder.webp';
import MetalDoorExteriorImage_WebP from '../img/metal-door-exterior.webp';
import DixonShelfImage_WebP from '../img/dixon-shelf.webp';
import BreakerBoxImage_WebP from '../img/breaker-box.webp';
import FloorBoxImage_WebP from '../img/floor-box.webp';
import FireBoxImage_WebP from '../img/fire-box.webp';
import SheetMetalImage_WebP from '../img/sheet-metal.webp';


import BackToTop from '../util/BackToTop';

import useIntersectionObserver from '@react-hook/intersection-observer'

import { useRef, useState } from 'react';

import FadeIn from '../util/FadeIn';

import SupportsWebP from "../util/SupportsWebP";
const hasWebP = SupportsWebP();


const styles = {
    MainContainer: {
        backgroundImage: hasWebP ? `url(${MainContainerImage_WebP})` : `url(${MainContainerImage})`,
        minHeight: "91vh",
        backgroundSize: "100% 100%",
    },
    HomeSection: {
      minHeight: "91vh",
      padding: "5vh"
    },
    SectionContainer1: {
      minHeight: "100vh",
      padding: "5vh"
    },
    MainText: {
      top: "10%",
      color: "#000",
      wordSpacing: "0.25em",
      padding: "1em 1em",
      textAlign: "center",
      animation: "fade-in 1s"
    },
    MainTextAlt: {
      top: "10%",
      color: "#eee",
      textShadow: "1px 2px 4px #888",
      wordSpacing: "0.25em",
      padding: "1em 1em",
      textAlign: "center",
      animation: "fade-in 1s"
    },
    SubContainerHeading: {
      color: "#000",
      align: "center",
      wordSpacing: "0.25em",
      padding: "1em 0em",
      textAlign: "center"
    },
    SubContainerHeadingAlt: {
      color: "#eee",
      align: "center",
      textShadow: "1px 2px 4px #888",
      wordSpacing: "0.25em",
      padding: "1em 0em",
      textAlign: "center"
    },
    OrdinaryHeading: {
      color: "#000",
      padding: "1em 0em",
    },
    OrdinaryHeadingAlt: {
      color: "#eee",
      padding: "1em 0em",
    },
    SubContainerText: {
      color: "#000",
      padding: "1em 0em"
    },
    SubContainerTextAlt: {
      color: "#eee",
      padding: "1em 0em"
    },
    ListColor: {
        color: "#000"
    },
    ListPrimaryText: {
        color: "#000",
        fontSize: "16px"
    },
    ListSecondaryText: {
        color: "#444"
    },
    ListColorAlt: {
        color: "#eee"
    },
    ListPrimaryTextAlt: {
        color: "#eee",
        fontSize: "16px"
    },
    CallToActionButton: {
      display: "flex",
      justifyContent: "space-around"
    },
    SectionImage: {
      padding: "20px 0px",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "85%",
      display: "block"
    },
    ProductImage: {
        padding: "20px 0px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "50%",
        display: "block"
      }
 };

const CableTraySection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#metal-door", "#metal-profiles",
        "#dixon-shelf", "#metal-enclosure", "#custom-products"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }

  return (
    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color0"}} id="cable-tray">
        
    
    <FadeIn showItem={showSection}>
    <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Cable Tray
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        NAHMA Precision Metal Products PLC manufactures different types of cable trays, cable ladders and 
        accessories as standard size as well as custom sizes tailored to client's specification.
    </Typography>
        <List dense={true} style={styles.ListColor}>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Cable ladder"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Elbow (90Deg)"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="T-shape Tray"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Cross-Sectional Joint"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Cable Tray Connectors (holding System)"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Reducers"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Lockers"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
        </List>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={CableTrayImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={CableTrayImage} alt="Cable Tray" />
      </picture>
    </Grid>
    </Grid>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        What is a cable tray?
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        The cable tray is used in the electrical wiring of buildings. It supports insulated
        electrical cables used for power distribution, control, and communication. The cable tray
        is useful for cable management in commercial and industrial construction.
    </Typography>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        What are the common types of cable trays?
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Most cable trays are made from galvanized steel, stainless steel, aluminium, or glass-fiber
        reinforced plastic. The exact material depends on the structure where it will be used.
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Also, a solid-bottom cable tray is used when the cables need maximum protection from damage.
        In contrast, a ventilated cable tray is used when air circulation and water & dust drainage
        is required.
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        What are the common types of cable ladders?
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Pre-galvanized carbon steel ladders are used for indoor applications, while hot-dipped
        galvanized ladders are widely used outdoors. Other types include stainless steel,
        power coated carbon steel, aluminium, and fibreglass reinforced plastic. The cable ladder
        can support a much larger amount of cables extending between two points and also allows for
        good air circulation, heat dissipation, and ease of installation.
    </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={CableLadderImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={CableLadderImage} alt="Cable Ladder" />
      </picture>
    </Grid>
    </Grid>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        What are cable tray accessories?
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Cable trays are not usually used by themselves. Commercial and insustrial sites often
        need to modify the cable tray in order to make room for smaller cables or to protect
        them from falling objects. Cable tray accessories such as Elbows, T-shaped trays, and
        cross-sectional joints provide a way to modify the cable tray using a straightforward process. 
    </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={CableTrayAccessoriesImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={CableTrayAccessoriesImage} alt="Cable Tray Accessories" />
      </picture>
    </Grid>
    </Grid>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        What are the advantages of cable trays?
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1 }}>
        You should use a cable tray when you need:
    </Typography>
        <List dense={true} style={styles.ListColor}>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Interference resistance"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="High corrosion resistance"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Long service life"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Fire and flame resistance"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Durablity"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Easily install new cables"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
        </List>
    
    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={primaryButtonSX} size="large" variant="contained"><b>Order Cable Tray</b></Button>
    </div>
    </span>
    </FadeIn>
  </Container>

  )
}

const MetalDoorSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);
  
  const location = useLocation();
  const lowerSections = ["#metal-profiles", "#dixon-shelf", "#metal-enclosure", "#custom-products"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown) && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color1"}} id="metal-door">
        
        
    <FadeIn showItem={showSection}>
      <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Exterior Metal Doors
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        NAHMA Precision Metal Products PLC manufactures different types of Hollow Metal Doors, Knock
        Down Frames, Welded Frames, standard sizes and custom industrial entry doors that are unique in 
        designs that are high quality to our client's satisfaction. Custom designs are available.
    </Typography>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        What are the different types of frames?
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        The two main types of frames are welded frames and knock down frames.
        Both types of frames are built using a series of strong and interconnected
        frames, which results in a light but very strong frame. The knock down frame
        does not require welding, so it can be used to assemble structures very quickly.
        It is also cheaper than the welded frame. But the welded frame is stronger than
        the knock down frame, because the frames are welded together. Welded frames are
        suitable for use in structures which must last for several years. Usually, the
        welded frames also have a good aesthetic design.
    </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={MetalDoorExteriorImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={MetalDoorExteriorImage} alt="Exterior Metal Door" />
      </picture>
    </Grid>
    </Grid>

    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={secondaryButtonSX} size="large" variant="contained"><b>Order Metal Door</b></Button>
    </div>
      </span>
    </FadeIn>
  </Container>
  )
}

const MetalProfilesSection = () => {
  const itemData = [
    {
      img: ProfileLAngleImage_WebP,
      type: "image/webp",
      title: 'L-angle',
      fallbacks: [
        ProfileLAngleImage
      ]
    },
    {
      img: ProfileUChannelImage_WebP,
      type: "image/webp",
      title: 'U-channel',
      fallbacks: [
        ProfileUChannelImage
      ]
    },
    {
      img: ProfileOmegaImage_WebP,
      type: "image/webp",
      title: 'Omega',
      fallbacks: [
        ProfileOmegaImage
      ]
    },
    {
      img: ProfileTrackImage_WebP,
      type: "image/webp",
      title: 'Track',
      fallbacks: [
        ProfileTrackImage
      ]
    },
    {
      img: ProfileStudImage_WebP,
      type: "image/webp",
      title: 'Stud',
      fallbacks: [
        ProfileStudImage
      ]
    },
  ];
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#dixon-shelf", "#metal-enclosure", "#custom-products"]
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown) && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color0"}} id="metal-profiles">
    <FadeIn showItem={showSection}>
      <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Metal Profiles
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1 }}>
        NAHMA Precision Metal Products PLC manufactures 5 types of gypsum board metal
        profiles at standard sizes. 
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Metal profile sheets due to their versatility mechanical and design properties can be used as
        roof and roof cladding, as external walls and wall cladding and also as floors. They are used
        in industry and in residential sector, and the two sectors can be used in both new construction
        and rehabilitation.
    </Typography>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        Types:
    </Typography>
        <List dense={true} style={styles.ListColor}>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Metal Stud"
                secondary="Used for partitions"
                primaryTypographyProps={styles.ListPrimaryText}
                secondaryTypographyProps={styles.ListSecondaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Metal Track"
                secondary="Used for partitions"
                primaryTypographyProps={styles.ListPrimaryText}
                secondaryTypographyProps={styles.ListSecondaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Main Channel (U-channel)"
                secondary="Used for ceilings"
                primaryTypographyProps={styles.ListPrimaryText}
                secondaryTypographyProps={styles.ListSecondaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Wall Angle (L-angle)"
                secondary="Used for ceilings"
                primaryTypographyProps={styles.ListPrimaryText}
                secondaryTypographyProps={styles.ListSecondaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Furring Channel (omega channel)"
                secondary="Used for ceilings"
                primaryTypographyProps={styles.ListPrimaryText}
                secondaryTypographyProps={styles.ListSecondaryText}
              />
            </ListItem>
        </List>

    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        What are metal profiles?
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Metal profiles are used in the construction of buildings to form an envelope around the structure.
        They provide protection from externally-applied loads such as snow and wind, and allow heat from
        inside the building to be dissipated outside. The envelope may also have cetrain cosmetic requirements,
        given that it is visible from the outside.
    </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <StandardImageList itemData={itemData} itemheight="150px" itemwidth="150px" height="800px"
        textProps={{"fontSize": "24px" }} />
    </Grid>
    </Grid>
    
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        The types of buildings using metal profiles: 
    </Typography>
    <List dense={true} style={styles.ListColor}>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Industrial buildings"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Commercial buildings"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Public buildings"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Sport buildings"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Educational buildings"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Offices and administration buildings"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Multi-story buildings"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Power plants"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Residential houses"
                primaryTypographyProps={styles.ListPrimaryText}
              />
              </ListItem>
              <ListItem>
                <ListItemIcon style={styles.ListColor}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText 
                  primary="High design demand buildings"
                  primaryTypographyProps={styles.ListPrimaryText}
                />
            </ListItem>
        </List>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={CeilingPanelsImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={CeilingPanelsImage} alt="Ceiling Panels" />
      </picture>
      <picture>
          <source srcset={PartitionWallImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={PartitionWallImage} alt="Partition Wall" />
      </picture>
    </Grid>
    </Grid>
    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={primaryButtonSX} size="large" variant="contained"><b>Order Metal Profiles</b></Button>
    </div>
    </span>
    </FadeIn>
  </Container>
  )
}

const DixonShelfSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#metal-enclosure", "#custom-products"]
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown) && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color1"}} id="dixon-shelf">
    
    <FadeIn showItem={showSection}>
      <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Dixon Shelf
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        NAHMA Precision Metal Products PLC manufactures different types of enclosures and boxes which
        meet international standard for both out door and indoor functions.
    </Typography>
    <Typography variant="h4"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1 }}>
        Types:
    </Typography>
            <List dense={true} style={styles.ListColor}>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="650 x 390 x 820 mm"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="2000 x 400 x 900 mm"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Custom Sizes available"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
        </List>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={DixonShelfImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={DixonShelfImage} alt="Dixon Shelf" />
      </picture>
    </Grid>
    </Grid>

    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        What is a Dixon shelf?
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        The Dixon shelf is an open-space storage rack that is designed for storing large quantities
        of items in a easy-to-access way. The shelves can be modified to accomidate light or heavy
        items, and can be configured for maximum storage space, loading capacity, and dimensions.
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Dixon shelves can be modified with accessories such as: angle-shaped uprights, protective
        plastic caps, double-side bends, dividers, and paint coats.
    </Typography>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        What are the advantages of Dixon shelves?
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1 }}>
        You should use a Dixon shelf if:
    </Typography>
        <List dense={true} style={styles.ListColor}>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="You need to store small or large quantities of items at a warehouse"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="You need to stack multiple shelves on top of each other"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="You need a high loading capacity for each shelf"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
        </List>

    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={secondaryButtonSX} size="large" variant="contained"><b>Order Dixon Shelf</b></Button>
    </div>
    </span>
    </FadeIn>
  </Container>
  )
}

const EnclosureSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#custom-products"]
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown) && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color0"}} id="metal-enclosure">
        
    <FadeIn showItem={showSection}>
      <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Metal Enclosures
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        NAHMA Precision Metal Products PLC manufactures different types of enclosures and boxes which
        meet international standard for both out door and indoor functions.
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Metal enclosures are commonly made from materials such as:
        diecast aluminium, extruded aluminium, carbon steel, and stainless steel and are suitable for
        indoor and outdoor use. Metal enclosures may also have hinges and be waterproof.
    </Typography>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        Types:
    </Typography>
        <List dense={true} style={styles.ListColor}>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Electrical Enclosures (NEMA and EEI Standards)"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Transformer enclosures"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Telecom Enclosures"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Junction Box"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Fire Boxes"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Floor Boxes"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
        </List>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={BreakerBoxImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={BreakerBoxImage} alt="Breaker Box" />
      </picture>
      <Typography variant="h6" sx={{textAlign: "center"}}>Breaker Box</Typography>

      <picture>
          <source srcset={FireBoxImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={FireBoxImage} alt="Fire Box" />
      </picture>
      <Typography variant="h6" sx={{textAlign: "center"}}>Fire Box</Typography>
      
      <picture>
          <source srcset={FloorBoxImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={FloorBoxImage} alt="Floor Box" />
      </picture>
      <Typography variant="h6" sx={{textAlign: "center"}}>Floor Box</Typography>
    </Grid>
    </Grid>

    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={primaryButtonSX} size="large" variant="contained"><b>Order Metal Enclosure</b></Button>
    </div>
    </span>
    </FadeIn>
  </Container>

  )
}

const CustomSheetMetalProductsSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  // Second condition prevents an infinite rendering loop.
  if (isIntersecting && !showSection) {
    setShowSection(true);
  }
  
  return (
      
    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color1"}} id="custom-products">
    
    <FadeIn showItem={showSection}>
      <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Custom Sheet Metal Products
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        Any kind of product
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Nahma Precision Metal Products PLC can also manufacture custom sheet metal products not listed above.
        The only requirement is that you tell us the kind of product you want and its specifications.
    </Typography>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        Ready to buy?
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Simply tell us the product, the specifications, the quantity, and the desired delivery date,
        and we will send you a quote and shipping time.
    </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={SheetMetalImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={SheetMetalImage} alt="Custom Sheet Metal Products" />
      </picture>
    </Grid>
    </Grid>

 

    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={secondaryButtonSX} size="large" variant="contained"><b>Order Now</b></Button>
    </div>
    </span>
    </FadeIn>
  </Container>
  
  )
}




const Products = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  // Second condition prevents an infinite rendering loop.
  if (isIntersecting && !showSection) {
    setShowSection(true);
  }

  const items = [
    { id: 0, value: "Cable Tray" },
    { id: 1, value: "Cable Ladder" },
    { id: 2, value: "Elbow (90Deg)" },
    { id: 3, value: "T-shape Tray" },
    { id: 4, value: "Cross-Sectional Joint" },
    { id: 5, value: "Cable Tray Connector (holding System)" },
    { id: 6, value: "Reducer" },
    { id: 7, value: "Locker" },
    { id: 8, value: "Exterior Metal Door" },
    { id: 9, value: "L-Angle Metal Profile" },
    { id: 10, value: "U-Channel Metal Profile" },
    { id: 11, value: "Omega Metal Profile" },
    { id: 12, value: "Track Metal Profile" },
    { id: 13, value: "Stud Metal Profile" },
    { id: 14, value: "Dixon Shelf (650 x 390 x 820 mm)" },
    { id: 15, value: "Dixon Shelf (2000 x 400 x 900 mm)" },
    { id: 16, value: "Dixon Shelf (custom size)" },
    { id: 17, value: "Electrical Enclosures (NEMA and EEI Standards)" },
    { id: 18, value: "Transformer Enclosure" },
    { id: 19, value: "Telecom Enclosure" },
    { id: 20, value: "Junction Box" },
    { id: 21, value: "Fire Box" },
    { id: 22, value: "Floor Box" },
    { id: 23, value: "Breaker Box" },
    { id: 24, value: "Other Metal Enclosure" },
  ]


    return (
      <div className="Products">
        <Header/>
        <Container ref={refSection} maxWidth={false} style={styles.MainContainer}>
        
        <FadeIn showItem={showSection}>
        <span>
        <Typography variant="h3"
            component="div" style={styles.SubContainerHeadingAlt} sx={{ flexGrow: 1 }}>
            Our Manufacturing Products
        </Typography>
        <Typography variant="h6"
            component="div" style={styles.MainTextAlt} sx={{ flexGrow: 1 }}>
            We have a large catalog of sheet metal and cable products that you can select from
            and configure to meet your business and industrial needs.
        </Typography>

        <Typography variant="h6"
            component="div" style={styles.MainTextAlt} sx={{ flexGrow: 1 }}>
            You can also quickly fill in your order using the form below.
        </Typography>
        <OrderForm items={items} />

        </span>
        </FadeIn>
      </Container>
        <CableTraySection/>
        <MetalDoorSection/>
        <MetalProfilesSection/>
        <DixonShelfSection/>
        <EnclosureSection/>
        <CustomSheetMetalProductsSection/>
        <Footer/>
        <BackToTop/>
      </div>
    )
  };

  export default Products;