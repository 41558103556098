export const ContactInfo = {
    place: ["Nefas Silk Lafto zone",
    <br key={0}/>,
    "Subcity Wereda 01. H. No New",
    <br key={1}/>,
    "Addis Ababa, Ethiopia"
    ],
    email: "Mohamed@nahmaplc.com",
    tel: "+251 94 402 3303",
    phone: "+251 94 421 0518"
}