import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ButtonGroup } from '@mui/material';

const isNumeric = (n) => {
    if (typeof n == "number") {
        return true;
    }
    else if (/^(\d+)\.?(\d*)$/.test(n)) {
        return true;
    }
    else {
        return false;
    }

}

const handleStep = (number, min, max, step, direction, onChange) => {
    number = Number(number);
    if (isNumeric(min) && number + step*direction < min) {
        if (onChange) onChange(min);
    }
    else if (isNumeric(max) && number + step*direction > max) {
        if (onChange) onChange(max);
    }
    else {
        if (onChange) onChange(number + step*direction);
    }
}

const handleNumberChange = (number, min, max, step, _default, setError, onChange) => {
    if (number === "") {
        if (_default) {
            if (onChange) onChange(_default);
        }
        else {
            if (onChange) onChange(0);
        }
    }
    if (!isNumeric(number)) {
        return;
    }
    if (onChange) onChange(number);
    let tmpnumber = Number(number);
    if (isNumeric(min) && tmpnumber < min) {
        setError(true);
        return;
    }
    else if (isNumeric(max) && tmpnumber > max) {
        setError(true);
        return;
    }
    let tempnum = tmpnumber;
    if (isNumeric(step) && step > 0) {
        while (Math.abs(step) < 1) {
            tempnum *= 10;
            step *= 10;
        }
        if (tempnum % step !== 0) {
            setError(true);
            return;
        }
    }
    setError(false);
    return;
}

export default function NumberInput(props){
  const [error, setError] = useState(false);

  
  let step = props.step;
  if (step === undefined) {
    step = 1;
  }


  return (
    <TextField
    InputProps={{
        readOnly: (typeof(props.inputProps) === "object") ? props.inputProps.readOnly : false,
        startAdornment: (
            <InputAdornment position='start'>

            {props.prefix}
            </InputAdornment>
          ),
        endAdornment: (
            <InputAdornment position='end'>
            {props.suffix}
            <ButtonGroup orientation="vertical" sx={{paddingLeft: "10px"}}>
              <IconButton size="small" onClick={
                (e) => handleStep(props.value, props.min, props.max, step, 1, props.onChange)
                }>
                <AddIcon style={{fontSize: "0.8rem"}}/>
              </IconButton>
                <IconButton size="small" onClick={
                (e) => handleStep(props.value, props.min, props.max, step, -1, props.onChange)
                }>
                    <RemoveIcon style={{fontSize: "0.8rem", paddingBottom:
                        (!props.variant || props.variant === "standard") ? "10px" : "0px"}}/>
              </IconButton>
              </ButtonGroup>
            </InputAdornment>
          ),
        inputProps: {
            step: step,
            min: props.min,
            max: props.max,
            type: 'text',
            onChange: (e) => {
                handleNumberChange(e.target.value,
                    props.min, props.max, step, props.default, setError, props.onChange);
                },
        }}}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        classes={props.classes}
        color={props.color}
        disabled={props.disabled}
        error={error}
        FormHelperTextProps={props.FormHelperTextProps}
        helperText={props.helperText}
        id={props.id}
        InputLabelProps={props.InputLabelProps}
        inputRef={props.inputRef}
        label={props.label}
        margin={props.margin}
        name={props.name}
        placeholder={props.placeholder}
        required={props.required}
        sx={{width: "26.2ch", ...props.sx}}
        value={props.value || 0}
        variant={props.variant}
    />
  )
}