import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import { styled } from "@mui/material/styles";

const StyledImage = styled('div')(
  ({theme}) => ({
    padding: theme.spacing(2, 2, 2, 2),
  })
);

export default function StandardImageList(props) {
  let itemwidth = props.itemwidth ? props.itemwidth : "auto";
  let itemheight = props.itemheight ? props.itemheight : "250px";
  let height = props.height ? props.height : "600px";
  let width = props.width ? props.width : "100%";
  let styles = {
    "height": itemheight,
    "width": itemwidth,
    "objectCover": "unset"
  };

  return (
    <ImageList sx={{ width: width, height: height }}>
      {props.itemData.map((item) => (
        <StyledImage>
        <ImageListItem key={props.itemData.indexOf(item)}> 
        <picture>
            <source srcset={item.img} type={item.type}/>
            {item.fallbacks.map((fallback) => (
            <img 
              style={styles}
              src={`${fallback}?w=${itemwidth}`}
              alt={item.title}
              loading="lazy"
            />
            ))}
        </picture>
          <ImageListItemBar
            title={<span style={props.textProps}>{item.title}</span>}
            position="below"
          />
        </ImageListItem>
        </StyledImage>
      ))}
    </ImageList>
  );
}
