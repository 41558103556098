import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
    palette: {
      primary: {
        light: '#4f5b62',
        main: '#263238',
        dark: '#000a12',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#6d6d6d',
        main: '#424242',
        dark: '#1b1b1b',
        contrastText: '#ffffff',
      },
      primaryInvert: {
        light: '#e2f1f8',
        main: '#b0bec5',
        dark: '#808e95',
        contrastText: '#000000',
      },
      secondaryInvert: {
        light: '#ffffff',
        main: '#eeeeee',
        dark: '#bcbcbc',
        contrastText: '#000000',
      },
      auxilliary: {
        color0: "#ffffff",
        color1: "#82b1ff"
      }
    },
  });

  export default theme;