import { useRef } from 'react';

import { Transition } from 'react-transition-group';


//default
const defaultDuration = 1500;
const defaultDelay = 0;


const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};


export default function FadeIn({showItem, duration, delay, children}) {
  
  if (duration === undefined) {
    duration = defaultDuration;
  }

  if (delay === undefined) {
    delay = defaultDelay;
  }
  
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out ${delay}ms`,
    opacity: 0,
  }
  

  const ref = useRef(null);

  return (
    <Transition mountOnEnter nodeRef={ref} in={showItem} timeout={duration+delay}>
    {state => (
      <span ref={ref} style={{
        ...defaultStyle,
        ...transitionStyles[state]
      }}>
    {children}
    </span>
    )}
    </Transition>
  )
}
