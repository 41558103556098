import './All.css';
import UseEmail from "../util/useEmail";

import Header from '../Header';
import Footer from '../Footer';
import { useLocation } from "react-router-dom";

import React from 'react';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import copyToClipboard from "copy-to-clipboard";

import { ContactInfo } from "../util/ContactInfo";


import MainContainerImage from '../img/contact.jpg';
import MainContainerImage_WebP from '../img/contact.webp';

import PlaceIcon from '@mui/icons-material/Place';
import TelIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MobileIcon from '@mui/icons-material/PhoneIphone';


import useIntersectionObserver from '@react-hook/intersection-observer'

import { useRef, useState } from 'react';

import FadeIn from '../util/FadeIn';

import SupportsWebP from "../util/SupportsWebP";
const hasWebP = SupportsWebP();


const styles = {
  MainContainer: {
      backgroundImage: hasWebP ? `url(${MainContainerImage_WebP})` : `url(${MainContainerImage})`,
      height: "100%",
      backgroundSize: "100% 100%"
  },
  SectionContainer1: {
    minHeight: "100vh",
    padding: "10vh"
  },
  ContactText: {
    paddingBottom: "5px",
    paddingTop: "5px",
    textAlign: "left"
  },
  SocialRow: {
    display: "flex",
    padding: "10px 0px"
  },
  SocialIcon: {
    padding: "5px 10px",
    color: "#222"
  },
  SocialIconAlt: {
    padding: "5px 10px",
    color: "#ddd"
  },
  GutterDescriptionText: {
    color: "#444",
    fontSize: "14px",
    paddingTop: 5
  },
  GutterDescriptionTextAlt: {
    color: "#bbb",
    fontSize: "14px",
    paddingTop: 5
  },
  GutterText: {
    color: "#444",
    textDecoration: "none"
  },
  GutterTextAlt: {
    color: "#bbb",
    textDecoration: "none"
  },
  SectionImage: {
    padding: "20px 0px",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    display: "block"
  },
  TwoElementInput: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
};


//TODO: Animate the alert with fade in/out and make the products single-column on small screens,
//then we can get to hosting this.
const Contact = () => {

  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  // Second condition prevents an infinite rendering loop.
  if (isIntersecting && !showSection) {
    setShowSection(true);
  }
  
  const location = useLocation();

  let entries = {};
  location.search.substring(1).split("&").map((v) => {
    const kv = v.split("=");
    entries[kv[0]] = kv[1];
    return v;
  });
  
  let initMessage = "";
  let messageIsValid = false;
  let messageJustInitialized = true;

  if (entries["item"] !== undefined &&
      entries["quantity"] !== undefined &&
      entries["shippingDate"] !== undefined) {
        const item = decodeURIComponent(entries["item"]);
        const quantity = entries["quantity"];
        const shippingDate = decodeURIComponent(entries["shippingDate"])
        initMessage = `Item: ${item},\nQuantity: ${quantity},\nShipping Date: ${shippingDate},Comments: \n`
  }
  if (initMessage !== "") {
    messageIsValid = true;
    messageJustInitialized = false;
  }
  
  const [name, setName] = useState({Name: "", isValid: false, justInitialized: true});
  const [phoneNumber, setPhoneNumber] = useState({PhoneNumber: "", isValid: false, justInitialized: true});
  const [message, setMessage] = useState({Message: initMessage,
    isValid: messageIsValid,
    justInitialized: messageJustInitialized});
  const [submitResult, setSubmitResult] = useState({submitted: false, success: true, title: "", message: ""});
  
  
  const endpoint = "https://public.herotofu.com/v1/91a213e0-76e5-11ed-a126-b172cf164538";
  const email = UseEmail(endpoint);
  const sendEmail = email.sendEmail;

  const sendMessage = () => {
    // Can be any data, static and dynamic
    sendEmail({
      name: name.Name,
      phone_number: phoneNumber.PhoneNumber,
      message: message.Message
    });
  };

  const handleNameChange = (e) => {   
    const value = e.target.value;
    setName({Name: value, isValid: value !== "", justInitialized: false});                 
  }
  const handlePhoneNumberChange = (e) => {        
    const value = e.target.value;
    setPhoneNumber({PhoneNumber: value, isValid: value !== "", justInitialized: false});                       
  }
  const handleMessageChange = (e) => {       
    const value = e.target.value;
    setMessage({Message: value, isValid: value !== "", justInitialized: false});                           
  }

  const ValidForm = () => {
    if (!name.isValid)  {
      setSubmitResult({submitted: true, success: false, title: "Invalid Name", message: "Please enter your name."})
      return false;
    }
    else if (!phoneNumber.isValid)  {
      setSubmitResult({submitted: true, success: false, title: "Invalid Phone Number", message: "Please enter your phone number."})
      return false;
    }
    else if (!message.isValid)  {
      setSubmitResult({submitted: true, success: false, title: "Invalid Message", message: "Please enter a message."})
      return false;
    }
    return true;
  }

  const CreateMailTo = () => {
    const suffix = `%0A%0A--------------%0AThis email was generated via the NAHMA contact form.%0ANahma Precision Metal Products PLC: https://nahmaplc.com %0ASent by:${name}%0APhone number:${phoneNumber}`
    const subject = "[NAMHA] New inquiry from: " + name.Name;
    const mail = "mailto:" + ContactInfo.email + "?subject=" + encodeURIComponent(subject) + "&body=" +
      encodeURIComponent(message.Message) + suffix;
    return mail;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!ValidForm()) return;
    setSubmitResult({submitted: true, success: true, title: "Thank you", message: "We have received your message."})
    sendMessage()
    //window.open(CreateMailTo())
  }

  const handleCopyToClipboard = (e) => {
    e.preventDefault();
    if (!ValidForm()) return;
    copyToClipboard(CreateMailTo())
    setSubmitResult({submitted: true, success: true, title: "Email link copied to clipboard", message: "Please paste the link into a browser to open your email client."})
  }

  const closeAlert = (e) => {
    setSubmitResult({submitted: false, success: submitResult.success, title: submitResult.title, message: submitResult.message});
  };



    return (
      <div className="Contact">
        <Header/>
        <Grid ref={refSection} container spacing={2} sx={{marginTop: 0}}>
        <Grid item xs={12} md={6} style={styles.MainContainer}>
        <Container maxWidth={false} className="ContactContainer">
        <FadeIn showItem={showSection}>
        <span>
        <Typography variant="h4"
            component="div" style={styles.GutterTextAlt} sx={{ flexGrow: 1 }}>
            Contact
        </Typography>
        <Typography variant="p"
            component="div" style={styles.GutterDescriptionTextAlt} sx={{ flexGrow: 1, textAlign: "justify" }}>
            Use this form to place an order. If you cannot see the form, just scroll down.
            Please specify the product, quantity, and desired delivery time in your message.
            Clicking the submit button will open your default email client where you can send
            the email to us. A sales rep will get back to you within 48-72 hours. We won't share
            your personal information with anyone.
        </Typography>
        <Box sx={{marginTop: 5}}>
                    <Box key="1" style={styles.SocialRow}>
                        <PlaceIcon style={styles.SocialIconAlt}></PlaceIcon>
                        <Typography variant="p"
                            component="div" style={styles.GutterTextAlt} sx={{ flexGrow: 1 }}>
                            {ContactInfo.place}
                        </Typography>
                    </Box>
                    <Box key="2" style={styles.SocialRow}>
                        <EmailIcon style={styles.SocialIconAlt}></EmailIcon>
                        <Typography variant="p"
                                component="div" sx={{ flexGrow: 1 }}>
                            <Link style={styles.GutterTextAlt}
                            href={"mailto:" + ContactInfo.email}>{ContactInfo.email}</Link>
                        </Typography>
                    </Box>
                    
                    <Box key="3" style={styles.SocialRow}>
                    <TelIcon style={styles.SocialIconAlt}></TelIcon>
                        <Typography variant="p"
                                component="div" sx={{ flexGrow: 1 }}>
                            <Link style={styles.GutterTextAlt}
                            href={"tel:" + ContactInfo.tel.replace(/ /g, '')}>{ContactInfo.tel}</Link>
                        </Typography>
                    </Box>
                    
                    <Box key="4" style={styles.SocialRow}>
                        <MobileIcon style={styles.SocialIconAlt}></MobileIcon>
                        <Typography variant="p"
                                component="div" sx={{ flexGrow: 1 }}>
                            <Link style={styles.GutterTextAlt}
                            href={"tel:" + ContactInfo.phone.replace(/ /g, '')}>{ContactInfo.phone}</Link>
                        </Typography>
                    </Box>
                  </Box>
        </span>
        </FadeIn>
        </Container>
        </Grid>
        <Grid item xs={12} md={6} sx={{"bgcolor": "auxilliary.color0"}}>
        <FadeIn ref={refSection} showItem={submitResult.submitted} duration={500}>
        <Alert severity={submitResult.success ? "success" : "error"}
          onClose={closeAlert}
          sx={{position: "absolute", margin: "0 5%"}}>
          <AlertTitle>{submitResult.title}</AlertTitle>
            {submitResult.message}
        </Alert>
        </FadeIn>
        <Container maxWidth={false} className="ContactContainer" sx={{"bgcolor": "#ffffff"}}>
        <FadeIn showItem={showSection}>
        <span>
        <Box
      component="form"
      noValidate
      autoComplete="off"
    >
      <div style={styles.TwoElementInput}>
        <TextField
          required
          id="outlined-required"
          label="Name"
          error={!name.justInitialized && !name.isValid}
          helperText={!name.justInitialized && !name.isValid &&
            "Name cannot be empty."}
          onChange={handleNameChange}
          value={name.Name}
          sx={{
            marginRight: 1,
            marginBottom: 1,
            width: "100%"
          }}
        />
        <TextField
          required
          id="outlined-required"
          label="Phone Number"
          error={!phoneNumber.justInitialized && !phoneNumber.isValid}
          helperText={!phoneNumber.justInitialized && !phoneNumber.isValid &&
            "Phone number cannot be empty."}
          onChange={handlePhoneNumberChange}
          value={phoneNumber.PhoneNumber}
          sx={{
            marginLeft: 1,
            marginBottom: 1,
            width: "100%"
          }}
        />
        </div>
        <TextField
          id="outlined-multiline-static"
          label="Message"
          error={!message.justInitialized && !message.isValid}
          helperText={!message.justInitialized && !message.isValid &&
            "Message cannot be empty."}
          required
          multiline
          rows={8}
          onChange={handleMessageChange}
          value={message.Message}
          sx={{
            width: "100%",
            marginBottom: 1
          }}
        />
        <Button href="/contact" sx={{width: "100%", marginBottom: 1}} size="large" variant="contained"
          onClick={handleSubmit}><b>Submit</b></Button>
        <Button href="/contact" sx={{width: "100%", textAlign: "center"}} size="large" variant="contained"
          onClick={handleCopyToClipboard}><b>Copy email to clipboard</b></Button>
        </Box>
        <Typography variant="p"
            component="div" sx={{ color: "#333", fontSize: "12px", marginTop: 2, flexGrow: 1 }}>
            Clicking "Copy email to clipboard" will create a mailto: link so you can open the email
            into your default email client. You must configure an email program or website to open
            these links.&nbsp;
            <a target="_blank" rel="noreferrer" style={{color: "inherit"}}
              href="https://www.makeuseof.com/tag/how-to-change-the-default-email-program-for-mailto-links/">
              Here is how you set the email client for mailto: links for all browsers on Windows, Mac,
              Android, and iOS.
            </a>
        </Typography>
        </span>
        </FadeIn>
          </Container>
        </Grid>
        </Grid>
        <Footer/>
      </div>
    )
  };
  
  export default Contact;