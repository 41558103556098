export const primaryButtonSX = {
    bgcolor: "primary.light",
    "&:hover": {
        bgcolor: "primary.main"
    }
};

export const secondaryButtonSX = {
    bgcolor: "secondary.light",
    "&:hover": {
        bgcolor: "secondary.main"
    }
};

export const primaryInvertButtonSX = {
    bgcolor: "primaryInvert.dark",
    "&:hover": {
        bgcolor: "primaryInvert.main"
    }
};

export const secondaryInvertButtonSX = {
    bgcolor: "secondaryInvert.dark",
    "&:hover": {
        bgcolor: "secondaryInvert.main"
    }
};

