import Container from "@mui/material/Container";

import { styled } from '@mui/material/styles';

const Item = styled(Container)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    padding: "40px 0px",
    color: theme.palette.text.secondary,
  }));
  
export default Item;