import './All.css';
import Header from '../Header';
import Footer from '../Footer';
import * as React from 'react';
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import Item from "../util/GridItem";

import MainContainerImage from '../img/cover.jpg';
import MainContainerImage_WebP from '../img/cover.webp';

import CallToActionImage from '../img/contact-3.jpg';
import CallToActionImage_WebP from '../img/contact-3.webp';

import CableTrayImage from '../img/cable-tray.jpg';
import MetalDoorImage from '../img/metal-door-exterior.jpg';
import MetalProfilesImage from '../img/metal-profiles-2.png';
import DixonShelfImage from '../img/dixon-shelf.jpg';
import FireBoxImage from '../img/fire-box.png';
import SheetMetalImage from '../img/sheet-metal.jpg';

import PunchingImage from '../img/service-punching.jpg';
import BendingImage from '../img/service-bending.png';
import ShearingImage from '../img/service-shearing.jpg';
import MachiningImage from '../img/service-machining.png';
import WeldingImage from '../img/service-welding.png';
import DesignImage from '../img/engineering-design.jpg';


import LogoAbogneImage from '../img/logo-abogne.png';
import LogoSigmaEngineeringImage from '../img/logo-sigma-engineering.png';
import LogoElectricWorldImage from '../img/logo-electric-world.jpg';
import LogoEaseImage from '../img/logo-ease.jpg';

import CableTrayImage_WebP from '../img/cable-tray.webp';
import MetalDoorImage_WebP from '../img/metal-door-exterior.webp';
import MetalProfilesImage_WebP from '../img/metal-profiles-2.webp';
import DixonShelfImage_WebP from '../img/dixon-shelf.webp';
import FireBoxImage_WebP from '../img/fire-box.webp';
import SheetMetalImage_WebP from '../img/sheet-metal.webp';

import PunchingImage_WebP from '../img/service-punching.webp';
import BendingImage_WebP from '../img/service-bending.webp';
import ShearingImage_WebP from '../img/service-shearing.webp';
import MachiningImage_WebP from '../img/service-machining.webp';
import WeldingImage_WebP from '../img/service-welding.webp';
import DesignImage_WebP from '../img/engineering-design.webp';

import LogoAbogneImage_WebP from '../img/logo-abogne.webp';
import LogoSigmaEngineeringImage_WebP from '../img/logo-sigma-engineering.webp';
import LogoElectricWorldImage_WebP from '../img/logo-electric-world.webp';
import LogoEaseImage_WebP from '../img/logo-ease.webp';


import { secondaryButtonSX } from '../util/sxButton';

import useIntersectionObserver from '@react-hook/intersection-observer'

import { useRef, useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import FadeIn from '../util/FadeIn';
import BackToTop from '../util/BackToTop';


import SupportsWebP from "../util/SupportsWebP";
const hasWebP = SupportsWebP();


const ArrowDownIcon = {
  color: "#eee",
  filter: "drop-shadow(1px 2px 4px #888)",
  fontSize: "144px"
}

const styles = {
  MainContainer: {
    backgroundImage: hasWebP ? `url(${MainContainerImage_WebP})` : `url(${MainContainerImage})`,
      height: "91vh",
      backgroundSize: "100% 100%"
  },
  CallToActionContainer: {
    backgroundImage: hasWebP ? `url(${CallToActionImage_WebP})` : `url(${CallToActionImage})`,
      height: "75vh",
      backgroundSize: "100% 100%"
  },
  SectionContainer1: {
    minHeight: "100vh",
    padding: "5vh"
  },
  SectionHeadingAlt: {
    minHeight: "4em",
    color: "#eee",
    textAlign: "center",
  },
  SectionHeading: {
    minHeight: "4em",
    color: "#000",
    textAlign: "center",
  },
  MainContainerCenter: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  MainContainerHeading: {
    color: "#eee",
    textTransform: "uppercase",
    textAlign: "center",
    textShadow: "1px 2px 4px #888",
    wordSpacing: "0.25em"
  },
  SubContainerHeadingAlt: {
    color: "#eee",
    textAlign: "center",
    textShadow: "1px 2px 4px #888",
    wordSpacing: "0.25em",
    padding: "1em 0em"
  },
  SubContainerHeading: {
    color: "#000",
    textAlign: "center",
    wordSpacing: "0.25em",
    padding: "1em 0em"
  },
   MainContainerSubHeading: {
    color: "#eee",
    textAlign: "center",
    textShadow: "1px 2px 4px #888",
    wordSpacing: "0.25em"
  },
  SubContainerGrid: {
    maxWidth: "50%"
  },
  ServiceIcon: {
    paddingTop: "10px",
    paddingBottom: "10px",
    color: "#eee"
  },
  ProductImage: {
    paddingTop: "10px",
    paddingBottom: "10px",
    width: "200px",
    minHeight: "200px"
  },
  SectionImage: {
    padding: "50px 0px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    maxWidth: "100px"
  },
  CallToActionButton: {
    display: "flex",
    justifyContent: "space-around"
  },
  ArrowDownHover: {
    ...ArrowDownIcon,
    transform: "translateY(20px)",
    transition: "transform 300ms"
  },
  ArrowDownLastHover: {
    ...ArrowDownIcon,
    transform: "translateY(0px)",
    transition: "transform 300ms"
  }
};

function ProductsSection() {
  
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#services", "#clients", "#order"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }

  return (
    
    <Container id="products" maxWidth={false} style={styles.SectionContainer1} sx={{"bgcolor": "auxilliary.color0"}}>
        
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Our Products
    </Typography>
    <Typography ref={refSection}  variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        NAHMA Precision Metal Products PLC manufactures different types of products by both standard size and custom order such as:
    </Typography>
    <FadeIn showItem={showSection}>
    <Grid container spacing={2}>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Cable Tray
    </Typography>
    <picture>
        <source srcset={CableTrayImage_WebP} type="image/webp"/>
        <img src={CableTrayImage} style={styles.ProductImage} alt="a Cable Tray" />
    </picture>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        The fundamental structure for holding cables together.
    </Typography>
    <Button href="/products#cable-tray" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Metal Door
    </Typography>
    <picture>
        <source srcset={MetalDoorImage_WebP} type="image/webp"/>
        <img src={MetalDoorImage} style={styles.ProductImage} alt="a Metal Door" />
    </picture>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Choose from exterior metal doors and customized metal frames.
    </Typography>
    <Button href="/products#metal-door" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Metal Profiles
    </Typography>  
    <picture>
        <source srcset={MetalProfilesImage_WebP} type="image/webp"/>
        <img src={MetalProfilesImage} style={styles.ProductImage} alt="Metal Profiles" />
    </picture>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Omega, L-angle, Stud, Track, and U-channel profiles at standard sizes.
    </Typography>
  <Button href="/products#metal-profiles" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Dixon Shelf
    </Typography>
    <picture>
        <source srcset={DixonShelfImage_WebP} type="image/webp"/>
        <img src={DixonShelfImage} style={styles.ProductImage} alt="Dixon Shelf" />
    </picture>
    <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Open-space storage rack with high storage capacities and dimensions.
    </Typography>
    <Button href="/products#dixon-shelf" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Metal Enclosure
    </Typography>
    <picture>
        <source srcset={FireBoxImage_WebP} type="image/webp"/>
        <img src={FireBoxImage} style={styles.ProductImage} alt="a Metal Enclosure" />
    </picture>
    <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Protect circuit boards and other electrical equipment with the most suitable enclosures.
    </Typography>
    <Button href="/products#metal-enclosure" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Sheet Metal Products
    </Typography>
    <picture>
        <source srcset={SheetMetalImage_WebP} type="image/webp"/>
        <img src={SheetMetalImage} style={styles.ProductImage} alt="Metal Sheets" />
    </picture>
    <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Order any custom metal sheet product and we will have it manufactured.
    </Typography>
    <Button href="/products#custom-products" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
</Grid>
</FadeIn>
    </Container>
  )
}

function ServicesSection() {
  
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#clients", "#order"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }

  return (
    
    <Container id="services" maxWidth={false} style={styles.SectionContainer1} sx={{"bgcolor": "auxilliary.color1"}}>
        
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Our Services
    </Typography>
    <Typography ref={refSection}  variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        NAHMA Precision Metal Products PLC provides quality services using modern sheet metal manufacturing technology:
    </Typography>
    <FadeIn showItem={showSection}>
    <Grid container spacing={2}>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        CNC Punching
    </Typography>
    <picture>
        <source srcset={PunchingImage_WebP} type="image/webp"/>
        <img src={PunchingImage} style={styles.ProductImage} alt="CNC Punching" />
    </picture>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        High-quality metal punching for components and finished products.
    </Typography>
    <Button href="/services#cnc-punching" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        CNC Bending
    </Typography>
    <picture>
        <source srcset={BendingImage_WebP} type="image/webp"/>
        <img src={BendingImage} style={styles.ProductImage} alt="CNC Bending" />
    </picture>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Perform simple and complex bends.
    </Typography>
    <Button href="/services#cnc-bending" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        CNC Shearing
    </Typography>
    <picture>
        <source srcset={ShearingImage_WebP} type="image/webp"/>
        <img src={ShearingImage} style={styles.ProductImage} alt="CNC Shearing" />
    </picture>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Shear up to 6mm mild steel using advanced and unique technical methods.
    </Typography>
    <Button href="/services#cnc-shearing" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Machining
    </Typography>
    <picture>
        <source srcset={MachiningImage_WebP} type="image/webp"/>
        <img src={MachiningImage} style={styles.ProductImage} alt="Machining" />
    </picture>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Low-volume facing, boring, and more using lathe, drilling and milling machines.
    </Typography>
  <Button href="/services#machining" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Metal Welding
    </Typography>
    <picture>
        <source srcset={WeldingImage_WebP} type="image/webp"/>
        <img src={WeldingImage} style={styles.ProductImage} alt="Metal Welding" />
    </picture>
    <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        MiG, Spot, and Arc Welding solutions.
    </Typography>
    <Button href="/services#metal-welding" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Engineering Design
    </Typography>
    <picture>
        <source srcset={DesignImage_WebP} type="image/webp"/>
        <img src={DesignImage} style={styles.ProductImage} alt="Engineering Design" />
    </picture>
    <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Design a model of any sheet metal product.
    </Typography>
    <Button href="/services#engineering-design" sx={secondaryButtonSX} size="large" variant="contained"><b>Learn More</b></Button>
</Item>
</Grid>
</Grid>
</FadeIn>
    </Container>
  )
}

function ClientsSection() {

  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#order"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }

  return (
  <Container id="clients" maxWidth={false} style={{padding: "5vh"}} sx={{"bgcolor": "auxilliary.color0"}}>
        
  <Typography ref={refSection} variant="h3"
      component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
      Clients
  </Typography>
  
  <FadeIn showItem={showSection}>
  <div style={{"display": "flex", "justifyContent": "space-around"}}>
    <picture>
        <source srcset={LogoAbogneImage_WebP} type="image/webp"/>
        <img style={styles.SectionImage} src={LogoAbogneImage} alt="Abogne" />
    </picture>
    <picture>
        <source srcset={LogoSigmaEngineeringImage_WebP} type="image/webp"/>
        <img style={styles.SectionImage} src={LogoSigmaEngineeringImage} alt="Sigma Engineering" />
    </picture>
    <picture>
        <source srcset={LogoElectricWorldImage_WebP} type="image/webp"/>
        <img style={styles.SectionImage} src={LogoElectricWorldImage} alt="Electric World" />
    </picture>
    <picture>
        <source srcset={LogoEaseImage_WebP} type="image/webp"/>
        <img style={styles.SectionImage} src={LogoEaseImage} alt="EASE" />
    </picture>
  </div>
  </FadeIn>
  </Container>
  )
}

function ContactUsSection() {

  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  // Second condition prevents an infinite rendering loop.
  if (isIntersecting && !showSection) {
    setShowSection(true);
  }

  return (
  <Container id="order" ref={refSection} maxWidth={false} style={styles.CallToActionContainer}
      sx={{"bgcolor": "auxilliary.color1"}}>
        
  <FadeIn showItem={showSection}>
  <Typography variant="h3"
      component="div" style={styles.SubContainerHeadingAlt} sx={{ flexGrow: 1 }}>
      Build Your Next Design
  </Typography>
  
  <Typography variant="h6"
      component="div" style={styles.SectionHeadingAlt} sx={{ flexGrow: 1 }}>
      Want some sheet metal products or services? We can get the job done.
      Order now and get a quote within 48-72 hours.
  </Typography>
  <div style={styles.CallToActionButton}>
    <Button href="/contact" color="info" size="large" variant="contained"><b>Buy Now</b></Button>
  </div>
  </FadeIn>
  </Container>
  )
}


function Home() {
  const refHome = useRef(null);
  const [showHome, setShowHome] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refHome);

  // Second condition prevents an infinite rendering loop.
  if (isIntersecting && !showHome) {
    setShowHome(true);
  }

  const [hover, setHover] = useState(false);
  const [lastHover, setLastHover] = useState(false);
  const toggleHover = () => {
    setLastHover(hover)
    setHover(!hover)
  }

  let transitionIcon = ArrowDownIcon;
  if (hover) {
    transitionIcon = styles.ArrowDownHover;
  }
  if (lastHover) {
    transitionIcon = styles.ArrowDownLastHover;
  }

  return (
    <>
    <div className="Home" ref={refHome}>
      <Header></Header>
      <Container maxWidth={false} style={styles.MainContainer}>
      <Container maxWidth={false} className="MainContainerCenter" style={styles.MainContainerCenter}>
      <FadeIn showItem={showHome} duration={1000}>
        <Typography variant="h2"
          component="div" style={styles.MainContainerHeading} sx={{ flexGrow: 1 }}>
          Top quality
        </Typography>
        </FadeIn>
      <FadeIn showItem={showHome} duration={750} delay={750}>
        <Typography variant="h3"
          component="div" style={styles.MainContainerSubHeading} sx={{ flexGrow: 1 }}>
          Sheet Metal Products And Services
        </Typography>
        </FadeIn>
        <FadeIn showItem={showHome} duration={750} delay={1500}>
        <Link href="#products"><ArrowDownwardIcon onMouseEnter={toggleHover} onMouseLeave={toggleHover} style={transitionIcon} /></Link>
        </FadeIn>
        </Container>

      </Container>
      <ProductsSection/>
      <ServicesSection/>
      <ClientsSection/>
      <ContactUsSection/>

        <Footer/>
        <BackToTop/>

    </div>
    </>
  );
}

export default Home;
