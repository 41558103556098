import React, { useState } from "react";

// importing material UI components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import Container from "@mui/material/Container";

import { secondaryButtonSX, primaryInvertButtonSX } from "./util/sxButton";

import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BusinessIcon from '@mui/icons-material/Business';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import BuildIcon from "@mui/icons-material/Build";

import Logo from './img/logo2.png';
import Logo_WebP from './img/logo2.webp';

import Drawer from "@mui/material/Drawer";

const styles = {
    AppBarContainer: {
    },
    Toolbar: {      
      display: "flex",
      justifyContent: "space-between"
    },
    DesktopMenu: {
        display: "flex"
    },
    DesktopButtons: {
      marginLeft: "0.5em",
      marginRight: "0.5em"
    },
    MobileButtons: {
      margin: "5px 0px",
      borderRadius: "4px"
    },
    LeftButtons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    Logo: {
      height: "2em",
      width: "140px"
    },
    ListItemText: {
      fontWeight: "bold",
    }
}


export default function Header() {
    
  /*
  react useState hook to save the current open/close state of the drawer,
  normally variables dissapear afte the function was executed
  */
  const [open, setState] = useState(false);

  
  /*
  function that is being called every time the drawer should open or close,
  the keys tab and shift are excluded so the user can focus between
  the elements with the keys
  */
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    //changes the function state according to the value of open
    setState(open);
  };

return (
	<AppBar position="static" sx={{bgcolor: "#222222"}} id="back-to-top-anchor">
        
      <Container style={styles.AppBarContainer} maxWidth={false}>
		<Toolbar sx={styles.Toolbar}>
		{/*Inside the IconButton, we
		can render various icons*/}
        
      <div style={styles.LeftButtons}>
        <div className="App-Mobile-Menu">
		<IconButton
			size="large"
			edge="start"
			color="inherit"
			aria-label="menu"
			sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
		>
			{/*This is a simple Menu
			Icon wrapped in Icon */}
			<MenuIcon />
		</IconButton>
        
        </div>
        <Divider sx={{mb: 2}} />


            {/* The outside of the drawer */}
            <Drawer
              //from which side the drawer slides in
              anchor="left"
              //if open is true --> drawer is shown
              open={open}
              //function that is called when the drawer should close
              onClose={toggleDrawer(false)}
            >
                {/* The inside of the drawer */}
                <Box sx={{
                  p: 2,
                  height: 1,
                  backgroundColor: "#ddd",
                }}>

                  {/* 
                  when clicking the icon it calls the function toggleDrawer 
                  and closes the drawer by setting the variable open to false
                  */}
                  <IconButton sx={{mb: 2}} onClick={toggleDrawer(false)}>
                    <CloseIcon />
                  </IconButton>

                  <Divider sx={{mb: 2}} />

                  <Box sx={{mb: 2}}>
                    <ListItemButton href="/products" style={styles.MobileButtons}>
                      <ListItemIcon>
                        <PrecisionManufacturingIcon sx={{color: "primary.main"}}/>
                      </ListItemIcon>
                      <ListItemText primary="Products" primaryTypographyProps={styles.ListItemText} />
                    </ListItemButton>

                    
                    <ListItemButton href="/services" style={styles.MobileButtons}>
                      <ListItemIcon>
                        <BuildIcon sx={{color: "primary.main"}}/>
                      </ListItemIcon>
                      <ListItemText primary="Services" primaryTypographyProps={styles.ListItemText} />
                    </ListItemButton>

                    <ListItemButton href="/about-us" style={styles.MobileButtons}>
                      <ListItemIcon>
                        <BusinessIcon sx={{color: "primary.main"}}/>
                      </ListItemIcon >
                      <ListItemText primary="About Us" primaryTypographyProps={styles.ListItemText} />
                    </ListItemButton>

                    <ListItemButton sx={primaryInvertButtonSX} style={styles.MobileButtons} variant="outlined" href="/contact">
                      <ListItemIcon>
                        <ContactPageIcon sx={{color: "primary.main"}} />
                      </ListItemIcon>
                      <ListItemText primary="Request Quote" primaryTypographyProps={styles.ListItemText} />
                    </ListItemButton>
                  </Box>

                </Box>
              
            </Drawer>
            

		{/* The Typography component applies
		default font weights and sizes */}

    <Button href="/">
      <picture>
        <source srcset={Logo_WebP} type="image/webp"/>
        <img src={Logo} style={styles.Logo} sx={{ flexGrow: 1 }} alt="Nahma Precision Metal Products" />
      </picture>
    </Button>
    </div>
        
        <div style={styles.DesktopMenu} className="App-Desktop-Menu">
		<Button href="/products" style={styles.DesktopButtons} color="inherit">Products</Button>
		<Button href="/services" style={styles.DesktopButtons} color="inherit">Services</Button>
		<Button href="/about-us" color="inherit" style={styles.DesktopButtons}>About Us</Button>
		<Button href="/contact" sx={secondaryButtonSX} style={styles.DesktopButtons} variant="contained">Request Quote</Button>
        </div>
		</Toolbar>
        </Container>
	</AppBar>
);
}
