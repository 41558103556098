import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PlaceIcon from '@mui/icons-material/Place';
import TelIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MobileIcon from '@mui/icons-material/PhoneIphone';

import "./pages/All.css";

import { styled } from '@mui/material/styles';

import { ContactInfo } from "./util/ContactInfo";


import Logo from './img/logo3.png';
import Logo_WebP from './img/logo3.webp';

const styles = {
    Footer: {
      minHeight: "30vh",
      padding: "2vh 0px",
      transform: "translateY(%25)"
    },
    FooterText: {
      color: "#eee",
      paddingBottom: "5px",
      paddingTop: "5px",
      textAlign: "left"
    },
    FooterGrid: {
      display: "flex",
      flexDirection: "row"
    },
    FinePrint: {
      transform: "translateY(%30)"
    },
    SocialMenu: {
        display: "flex",
        flexDirection: "column"
    },
    SocialRow: {
        display: "flex",
    },
    SocialIcon: {
      padding: "5px 10px",
      color: "#eee"
    },
    Logo: {
      height: "10rem",
      float: "none"
    }
  };


  
const FooterItem = styled(Container)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  

export default function Footer() {
    return (
        <Container maxWidth={false} style={styles.Footer} sx={{"bgcolor": "secondary.dark"}}>
        <Grid item xs={4} style={styles.FooterGrid}>
            <FooterItem>
                <div style={{"display": "flex"}}>
                <picture>
                    <source srcset={Logo_WebP} type="image/webp"/>
                    <img src={Logo} className="Footer-logo" align="left" style={styles.Logo}
                    sx={{ flexGrow: 1 }} alt="Nahma Precision Metal Products"/>
                </picture>
                </div>
                <Box style={styles.FinePrint}>
                    <Typography variant="p"
                        component="div" style={styles.FooterText} sx={{ flexGrow: 1 }}>
                        Copyright © 2022 Nahma Precision Metal Products PLC
                    </Typography>
                </Box>
            </FooterItem>
            <FooterItem>
                <Box style={styles.SocialMenu}>
                    <Box style={styles.SocialRow}>
                        <PlaceIcon style={styles.SocialIcon} sx={{color: "#fff"}}></PlaceIcon>
                        <Typography variant="p"
                            component="div" style={styles.FooterText} sx={{ flexGrow: 1 }}>
                            {ContactInfo.place}
                        </Typography>
                    </Box>
                    <Box style={styles.SocialRow}>
                        <EmailIcon style={styles.SocialIcon} sx={{color: "#fff"}}></EmailIcon>
                        <Typography variant="p"
                                component="div" style={styles.FooterText} sx={{ flexGrow: 1 }}>
                            <a style={{color: "#eee", textDecoration: "none"}}
                                href={"mailto:" + ContactInfo.email}>{ContactInfo.email}</a>
                        </Typography>
                    </Box>
                    
                    <Box style={styles.SocialRow}>
                    <TelIcon style={styles.SocialIcon} sx={{color: "#fff"}}></TelIcon>
                        <Typography variant="p"
                                component="div" style={styles.FooterText} sx={{ flexGrow: 1 }}>
                            <a style={{color: "#eee", textDecoration: "none"}}
                                href={"tel:" + ContactInfo.tel.replace(/ /g, '')}>{ContactInfo.tel}</a>
                        </Typography>
                    </Box>
                    
                    <Box style={styles.SocialRow}>
                        <MobileIcon style={styles.SocialIcon} sx={{color: "#fff"}}></MobileIcon>
                        <Typography variant="p"
                                component="div" style={styles.FooterText} sx={{ flexGrow: 1 }}>
                            <a style={{color: "#eee", textDecoration: "none"}}
                                href={"tel:" + ContactInfo.phone.replace(/ /g, '')}>{ContactInfo.phone}</a>
                        </Typography>
                    </Box>
        </Box>
        </FooterItem>
        
        </Grid>
          </Container>
    )
}