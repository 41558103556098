import './All.css';

import Header from '../Header';
import Footer from '../Footer';

import * as React from 'react';
import { useLocation } from "react-router-dom";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MainContainerImage from '../img/factory.jpg';
import MainContainerImage_WebP from '../img/factory.webp';

import VisibilityIcon from '@mui/icons-material/Visibility';
import WorkIcon from '@mui/icons-material/Work';
import DiamondIcon from '@mui/icons-material/Diamond';

import { secondaryButtonSX } from "../util/sxButton";
import Item from "../util/GridItem";
import BackToTop from '../util/BackToTop';
import useIntersectionObserver from '@react-hook/intersection-observer'
import { useRef, useState } from 'react';
import FadeIn from '../util/FadeIn';

import SupportsWebP from "../util/SupportsWebP";
const hasWebP = SupportsWebP();

const styles = {
  MainContainer: {
      backgroundImage: hasWebP ? `url(${MainContainerImage_WebP})` : `url(${MainContainerImage})`,
      height: "91vh",
      backgroundSize: "100% 100%",
  },
  HomeSection: {
    minHeight: "91vh",
    padding: "5vh"
  },
  SectionContainer1: {
    minHeight: "100vh",
    padding: "5vh"
  },
  MainText: {
    top: "10%",
    position: "relative",
    color: "#000",
    wordSpacing: "0.25em",
    padding: "1em 1em",
    textAlign: "center"
  },
  MainTextAlt: {
    top: "10%",
    position: "relative",
    color: "#eee",
    textShadow: "1px 2px 4px #888",
    wordSpacing: "0.25em",
    padding: "1em 1em",
    textAlign: "center"
  },
  SubContainerHeading: {
    color: "#000",
    wordSpacing: "0.25em",
    padding: "1em 0em",
    textAlign: "center"
  },
  SubContainerHeadingAlt: {
    color: "#eee",
    textShadow: "1px 2px 4px #888",
    wordSpacing: "0.25em",
    padding: "1em 0em",
    textAlign: "center"
  },
  SubContainerText: {
    color: "#000",
    padding: "1em 0em"
  },
  SubContainerCenterText: {
    color: "#000",
    textAlign: "center",
    padding: "1em 0em"
  },
  SubContainerTextAlt: {
    color: "#eee",
    padding: "1em 0em"
  },
  CallToActionButton: {
    display: "flex",
    justifyContent: "space-around"
  },
  SectionImage: {
    padding: "20px 0px",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "85%",
    display: "block"
  },
  ListItemText: {
    fontSize: "2em"
  },
   ListItemSubText: {
    fontSize: "1.25em"
  }
};

const CompanyDescriptionSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#vision-mission", "#team", "#order"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container id="company" ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color1"}}>
        
    <FadeIn showItem={showSection}>
    <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Company Description
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Nahma Precision Metal Products PLC is a multi functioning company that mainly focuses on
        sheet metal forming processes. Nahma has served different target customers, industrial and
        commercial establishments, construction companies, telecommunication companies, manufacturers, 
        wholesalers, individual users and many more to achieve long term supply chains.
        </Typography>
        <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Nahma Precision Metal Products PLC started off as a leading manufacturer and exporter
        of sheet metal production. Since its inception, our production facility has marked
        perfection in terms of quality and reliability of cable trays, cable ladders,
        shelves, and other sheet metal products, delivered worldwide.
        </Typography>
        <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
         We pride ourselves by providing quality service and products using a modern precision Sheet metal
         manufacturing technology. We provide high quality products, standard and customized designs and
         full metal forming services. We offer a variety of solutions to assist with all clients'
         implementation requirements.
    </Typography>
    </span>
    </FadeIn>
  </Container>
  )
}


const VisionAndMissionSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#team", "#order"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container id="vision-mission" ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color0"}}>
        
    <FadeIn showItem={showSection}>
    <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Vision And Mission
    </Typography>
    <List style={styles.ListColor}>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Vision"
                secondary="We strive to be the first and best supplier of choice within our industry."
                primaryTypographyProps={styles.ListItemText}
                secondaryTypographyProps={styles.ListItemSubText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Mission"
                secondary="Nahma is committed to being a world class precision sheet metal
                fabricator with integrity, quality & service to our clients. We strive to 
                continually strengthen relationships with our customers and stakeholders"
                primaryTypographyProps={styles.ListItemText}
                secondaryTypographyProps={styles.ListItemSubText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <DiamondIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Core Values"
                secondary="Integrity, Quality Service, Authenticity, Accountability, Growth, 
                Innovation, Creativity, Improvement and Leadership."
                primaryTypographyProps={styles.ListItemText}
                secondaryTypographyProps={styles.ListItemSubText}
              />
            </ListItem>
      </List>
    </span>
    </FadeIn>
  </Container>
  )
}

const TeamSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#order"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container id="team" ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color1"}}>
        
    <FadeIn showItem={showSection}>
    <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        The Team
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerCenterText} sx={{ flexGrow: 1 }}>
        Nahma Precision Metal Products PLC employs the top talent in the manufacturing sector:
        </Typography>
    <Grid container spacing={2}>
<Grid item xs={12} sm={6} md={3}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Mohamed Ahmed
    </Typography>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Chief Executive Officer
      </Typography>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={3}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Netsanet Kassahun
    </Typography>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Chief Engineering Manager & Sales
    </Typography>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={3}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Adem Abdo
    </Typography>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Plant Manager
    </Typography>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={3}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Abenezer
    </Typography>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Mechanical Engineer
    </Typography>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={3}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Muluken Ayele
    </Typography>
    <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Senior Mechanical Engineer
    </Typography>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={3}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Addis
    </Typography>
    <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Maintenance Engineer
    </Typography>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={3}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Ermias
    </Typography>
    <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Chief Accountant
    </Typography>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={3}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Adem Jefar
    </Typography>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Mechanical Engineer
    </Typography>
</Item>
</Grid>
<Grid item xs={12} sm={6} md={3}>
<Item>
<Typography variant="h6"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Berhanu Gezaheg
    </Typography>
  <Typography variant="p"
        component="div" style={styles.SectionHeading} sx={{ flexGrow: 1 }}>
        Architecture Engineer
    </Typography>
</Item>
</Grid>
</Grid>
    </span>
    </FadeIn>
  </Container>
  )
}

const BuyMetalProductsAndServicesSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  // Second condition prevents an infinite rendering loop.
  if (isIntersecting && !showSection) {
    setShowSection(true);
  }
  
  return (
    <Container id="order" ref={refSection} maxWidth={false} style={styles.SectionContainer1}
    sx={{"bgcolor": "auxilliary.color0"}}>
        
    <FadeIn showItem={showSection}>
    <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Buy Metal Products And Services
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        Nahma Precision Metal Products PLC is a well-known importer and manufacturer of custom metal products.
        Officially located in Addis Ababa, Adis Abeba, Ethiopia, the company is not just restricted towards
        selling, but also supplies other products.
        </Typography>
        <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        If you would like to order a product or service from us, just request a quote from us and specify the product
        and quantity of the order and the desired delivery date.
        </Typography>
    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={secondaryButtonSX} size="large" variant="contained"><b>Request Quote</b></Button>
    </div>
    </span>
    </FadeIn>
  </Container>
  )
}


const AboutUs = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  // Second condition prevents an infinite rendering loop.
  if (isIntersecting && !showSection) {
    setShowSection(true);
  }
  
    return (
      <div className="AboutUs">
        <Header/>
        <Container ref={refSection} maxWidth={false} style={styles.MainContainer}
          sx={{"bgcolor": "auxilliary.color0"}}>
        
        <FadeIn showItem={showSection}>
        <span>
        <Typography variant="h3"
            component="div" style={styles.SubContainerHeadingAlt} sx={{ flexGrow: 1 }}>
            About Us
        </Typography>
        <Typography variant="h6"
            component="div" style={styles.MainTextAlt} sx={{ flexGrow: 1 }}>
            NAHMA Precision Metals Products PLC was established in 2017 and operates in Addis Ababa, Ethiopia.
            It is a topmost venture in Ethiopia that is trading all over the world.
        </Typography>
        </span>
        </FadeIn>
      </Container>
        <CompanyDescriptionSection/>
        <VisionAndMissionSection/>
        <TeamSection/>
        <BuyMetalProductsAndServicesSection/>
        <Footer/>
        <BackToTop/>
      </div>
    )
  };
  
  export default AboutUs;