import { useState } from 'react';
import WideNumberInput from './util/WideNumberInput';
import NumberInput from './util/NumberInput';
import DateFnsUtils from '@date-io/date-fns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { formatISO } from 'date-fns';

function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

const styles = {
  OrderFormContainer: {
    display: "inline-flex",
    position: "relative",
    alignItems: "flex-start",
    flexDirection: "row",
    left: "50%",
    transform: "translateX(-50%)",
    flexWrap: "wrap", 
    justifyContent: "space-around"
  },
  OrderForm: {
    margin: "10px 10px"
  },
  CallToActionButton: {
    display: "flex",
    justifyContent: "space-around",
  },
}

export default function OrderForm(props) {
  const [product, setProduct] = useState('');
  const [quantity, setQuantity] = useState(1);
  let today = new Date();
  const [shippingDate, setShippingDate] = useState(today);

  const indexed_product = product || 0;
  const link = "/contact?" + encodeQueryData(
    {"item": props.items[indexed_product].value,
    "quantity": quantity,
    "shippingDate": formatISO(shippingDate)})

    return (
    <>
        <div style={styles.OrderFormContainer}>
        <FormControl className="order-form" >
  <Select sx={{minWidth: "30ch", ...styles.OrderForm}}
    labelId="order-product-label"
    id="order-product"
    label="Product"
    value={product}
    onChange={(e) => setProduct(e.target.value)}
  >
    {props.items.map((item)=>
    <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
    )}
  </Select>
        <InputLabel id="order-product" sx={styles.OrderForm}>Product</InputLabel>
</FormControl>
        
          <span className="DesktopOnly">

          <FormControl className="order-form" >
        <NumberInput sx={styles.OrderForm} label="Quantity" min={1} value={quantity} variant="outlined"
        onChange={(number) => setQuantity(number)} />
        </FormControl>
        </span>
        <span className="DesktopOnly">

        <FormControl className="order-form" >
        <LocalizationProvider dateAdapter={DateFnsUtils}>
        <DesktopDatePicker
          label="Shipping Date"
          inputFormat="yyyy-MM-dd"
          value={shippingDate}
          onChange={(date) => setShippingDate(date)}
          minDate={today}
          renderInput={(params) => <TextField sx={styles.OrderForm}  {...params} />}
        />
        </LocalizationProvider>
        </FormControl>
          </span>

          <span className="MobileOnly">

          <FormControl className="order-form" >
        <WideNumberInput sx={styles.OrderForm} label="Quantity" min={1} value={quantity} variant="outlined"
        onChange={(number) => setQuantity(number)} />
        </FormControl>
        </span>
        <span className="MobileOnly">
          <FormControl className="order-form" >
        <LocalizationProvider dateAdapter={DateFnsUtils}>
        <MobileDatePicker
          label="Shipping Date"
          inputFormat="yyyy-MM-dd"
          value={shippingDate}
          onChange={(date) => setShippingDate(date)}
          minDate={today}
          renderInput={(params) => <TextField sx={styles.OrderForm} {...params}  />}
        />
        </LocalizationProvider>
        </FormControl>
          </span>
        </div>
        
      <div style={styles.CallToActionButton}>
        <Button href={link} startIcon={<ShoppingCartIcon/>} size="large"
          className="order-button" color="info" sx={{marginBottom: "50px"}}>Order Now</Button>
      </div>
      </>
    )
}