import Header from '../Header';
import Footer from '../Footer';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

const styles = {
  SectionContainer1: {
    minHeight: "100vh",
    padding: "5vh"
  },
  SubContainerHeading: {
    color: "#000",
    align: "center",
    wordSpacing: "0.25em",
    padding: "1em 0em",
    textAlign: "center"
  },
  SubContainerHeadingAlt: {
    color: "#eee",
    align: "center",
    textShadow: "1px 2px 4px #888",
    wordSpacing: "0.25em",
    padding: "1em 0em",
    textAlign: "center"
  },
  CallToActionButton: {
    display: "flex",
    justifyContent: "space-around"
  }
};



const NoPage = () => {
    return (
      <div className="NoPage">
        <Header/>
        <Container maxWidth={false} style={styles.SectionContainer1} sx={{"bgcolor": "auxilliary.color0"}}>
        
        <Typography variant="h1"
            component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
            404
        </Typography>
        <Typography variant="h6"
            component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
            This page does not exist. We are sorry for the inconvenience.
        </Typography>
        <div style={styles.CallToActionButton}>
        <Button href="/" sx={{bgcolor: "secondary.light"}} size="large" variant="contained"><b>Go To Homepage</b></Button>
        </div>
      </Container>
        <Footer/>
      </div>
    )
  };
  
  export default NoPage;