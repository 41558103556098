import './All.css';

import Header from '../Header';
import Footer from '../Footer';
import OrderForm from '../OrderForm';

import * as React from 'react';
import { useLocation } from "react-router-dom";
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import BackToTop from '../util/BackToTop';

import { primaryButtonSX, secondaryButtonSX } from "../util/sxButton";

import PunchingImage from '../img/service-punching.jpg';
import Punching2Image from '../img/service-punching-2.jpg';
import BendingImage from '../img/service-bending.png';
import ShearingImage from '../img/service-shearing.jpg';
import MachiningImage from '../img/service-machining.png';
import WeldingImage from '../img/service-welding.png';
import DesignImage from '../img/engineering-design.jpg';
import MainContainerImage from '../img/welding.jpg';


import PunchingImage_WebP from '../img/service-punching.webp';
import Punching2Image_WebP from '../img/service-punching-2.webp';
import BendingImage_WebP from '../img/service-bending.webp';
import ShearingImage_WebP from '../img/service-shearing.webp';
import MachiningImage_WebP from '../img/service-machining.webp';
import WeldingImage_WebP from '../img/service-welding.webp';
import DesignImage_WebP from '../img/engineering-design.webp';
import MainContainerImage_WebP from '../img/welding.webp';


import useIntersectionObserver from '@react-hook/intersection-observer'

import { useRef, useState } from 'react';

import FadeIn from '../util/FadeIn';

import SupportsWebP from "../util/SupportsWebP";
const hasWebP = SupportsWebP();


const styles = {
  MainContainer: {
      backgroundImage: hasWebP ? `url(${MainContainerImage_WebP})` : `url(${MainContainerImage})`,
      height: "91vh",
      backgroundSize: "100% 100%",
  },
    HomeSection: {
      minHeight: "91vh",
      padding: "5vh"
    },
    SectionContainer1: {
      minHeight: "100vh",
      padding: "5vh"
    },
    MainText: {
      top: "10%",
      color: "#000",
      wordSpacing: "0.25em",
      padding: "1em 1em",
      textAlign: "center",
      animation: "fade-in 1s"
    },
    MainTextAlt: {
      top: "10%",
      color: "#eee",
      textShadow: "1px 2px 4px #888",
      wordSpacing: "0.25em",
      padding: "1em 1em",
      textAlign: "center",
      animation: "fade-in 1s"
    },
    SubContainerHeading: {
      color: "#000",
      align: "center",
      wordSpacing: "0.25em",
      padding: "1em 0em",
      textAlign: "center"
    },
    SubContainerHeadingAlt: {
      color: "#eee",
      align: "center",
      textShadow: "1px 2px 4px #888",
      wordSpacing: "0.25em",
      padding: "1em 0em",
      textAlign: "center"
    },
    OrdinaryHeading: {
      color: "#000",
      padding: "1em 0em",
    },
    OrdinaryHeadingAlt: {
      color: "#eee",
      padding: "1em 0em",
    },
    SubContainerText: {
      color: "#000",
      padding: "1em 0em"
    },
    SubContainerTextAlt: {
      color: "#eee",
      padding: "1em 0em"
    },
    ListColor: {
        color: "#000"
    },
    ListPrimaryText: {
        color: "#000",
        fontSize: "16px"
    },
    ListColorAlt: {
        color: "#eee"
    },
    ListPrimaryTextAlt: {
        color: "#eee",
        fontSize: "16px"
    },
    CallToActionButton: {
      display: "flex",
      justifyContent: "space-around",
      padding: "40px 0px"
    },
    SectionImage: {
      padding: "20px 0px",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "85%",
      display: "block"
    },
    ProductImage: {
        padding: "20px 0px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "50%",
        display: "block"
      }
 };

const PunchingSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#cnc-bending", "#cnc-shearing",
        "#machining", "#metal-welding", "#engineering-design"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }

  return (
    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color0"}} id="cnc-punching">
        
    
    <FadeIn showItem={showSection}>
    <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        CNC sheet Metal Punching services
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        CNC metal punching is a key component in the high volume, precision metal 
        manufacturing process here at NAHMA Precision Metal Products PLC. Whether it be 
        Aluminum, copper, brass or steel sheet metal, we offer CNC metal punching for 
        components or finished products that are an accurate and cost effective solution for 
        a wide variety of applications.
    </Typography>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        Details:
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1 }}>
        We use a Trumpf Trumatic 200 and Trumpf Trumatic 200R for punching.
    </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={PunchingImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={PunchingImage} alt="Trumpf Trumatic 2000 Punching Machine" />
      </picture>
      <picture>
          <source srcset={Punching2Image_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={Punching2Image} alt="Trumpf Trumatic 2000R Punching Machine" />
      </picture>
    </Grid>
    </Grid>

    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={primaryButtonSX} size="large" variant="contained"><b>Order Punching Service</b></Button>
    </div>
    </span>
    </FadeIn>
  </Container>

  )
}

const BendingSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#cnc-shearing", "#machining", "#metal-welding", "#engineering-design"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color1"}} id="cnc-bending">
        
        
    <FadeIn showItem={showSection}>
      <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        CNC sheet Metal Bending services
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        NAHMA Precision Metal Products PLC offers CNC bending and forming tools 
        that can accommodate everything from the simplest to the most complex bends for 
        clients' fabrication needs.
    </Typography>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        Details:
    </Typography>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        We use a Trumpf TrumaBend v50 for 1m thickness bending, Trumpf TrumaBend v85
        for 2m thickness bending, and Trumpf TrumaBend v130 for 3m thickness bending.
    </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={BendingImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={BendingImage} alt="Trumpf TrumaBend V85 Bending Machine" />
      </picture>
    </Grid>
    </Grid>

    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={secondaryButtonSX} size="large" variant="contained"><b>Order Bending Service</b></Button>
    </div>
      </span>
    </FadeIn>
  </Container>
  )
}

const ShearingSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#machining", "#metal-welding", "#engineering-design"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color0"}} id="cnc-shearing">
    <FadeIn showItem={showSection}>
      <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        CNC sheet Metal Shearing service
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        NAHMA Precision Metal Products PLC offers CNC shearing services.
        Our metal shearing services include shearing up to 6 mm mild steel,
        cutting to length, blanking, saw cutting and miter cutting. Nahma's
        shearing is performed using advanced and unique technical methods.
    </Typography>
    <List dense={true} style={styles.ListColor}>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Constant Innovation"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Prioritizing safety"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Sustainability"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Accuracy"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
        </List>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={ShearingImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={ShearingImage} alt="CNC Shearing Machine" />
      </picture>
    </Grid>
    </Grid>

    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={primaryButtonSX} size="large" variant="contained"><b>Order Shearing Service</b></Button>
    </div>
    </span>
    </FadeIn>
  </Container>
  )
}

const MachiningSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#metal-welding", "#engineering-design"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color1"}} id="machining">
    
    <FadeIn showItem={showSection}>
      <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Machining
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1 }}>
          At NAHMA Precision Metal Products PLC, we do various machining processes
          using Lathe, drilling and milling machines.
    </Typography>
    <Typography variant="h4"
        component="div" style={styles.OrdinaryHeading} sx={{ flexGrow: 1 }}>
        List of machining processes:
    </Typography>
        <List dense={true} style={styles.ListColor}>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Boring"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Drilling"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Knurling"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Chamfering"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Parting Off"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Grooving"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Forming"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Tapper Turning"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Threading"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Reaming"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Tapping"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
        </List>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={MachiningImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={MachiningImage} alt="Machining" />
      </picture>
    </Grid>
    </Grid>

    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={secondaryButtonSX} size="large" variant="contained"><b>Order Machining Service</b></Button>
    </div>
    </span>
    </FadeIn>
  </Container>
  )
}

const WeldingSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  const location = useLocation();
  const lowerSections = ["#engineering-design"];
  const lowerSectionShown = lowerSections.includes(location.hash);

  // Second condition prevents an infinite rendering loop.
  if ((isIntersecting || lowerSectionShown)  && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color0"}} id="metal-welding">
        
    <FadeIn showItem={showSection}>
      <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Metal Welding
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        At Nahma Precision metal Product PLC, we offer various welding services for 
        our clients' satisfaction and needs.
    </Typography>
    <List dense={true} style={styles.ListColor}>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="MiG Welding"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Spot welding"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon style={styles.ListColor}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Arc welding"
                primaryTypographyProps={styles.ListPrimaryText}
              />
            </ListItem>
        </List>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={WeldingImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={WeldingImage} alt="Welding" />
      </picture>
    </Grid>
    </Grid>

    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={secondaryButtonSX} size="large" variant="contained"><b>Order Welding Service</b></Button>
    </div>
    </span>
    </FadeIn>
  </Container>

  )
}

const EngineeringDesignSection = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  // Second condition prevents an infinite rendering loop.
  if (isIntersecting && !showSection) {
    setShowSection(true);
  }
  
  return (

    <Container ref={refSection} maxWidth={false} style={styles.SectionContainer1}
      sx={{"bgcolor": "auxilliary.color1"}} id="engineering-design">
        
    <FadeIn showItem={showSection}>
      <span>
    <Typography variant="h3"
        component="div" style={styles.SubContainerHeading} sx={{ flexGrow: 1 }}>
        Engineering Design
    </Typography>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <Typography variant="h6"
        component="div" style={styles.SubContainerText} sx={{ flexGrow: 1, textAlign: "justify" }}>
        At Nahma Precision metal Product PLC, we can design custom metal products for clients
        using indistry-leading CAD software such as AutoCAD and SolidWorks.
    </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <picture>
          <source srcset={DesignImage_WebP} type="image/webp"/>
          <img style={styles.ProductImage} src={DesignImage} alt="Engineering Design" />
      </picture>
    </Grid>
    </Grid>

    <div style={styles.CallToActionButton}>
        <Button href="/contact" sx={secondaryButtonSX} size="large" variant="contained"><b>Order Design Service</b></Button>
    </div>
    </span>
    </FadeIn>
  </Container>

  )
}



const Services = () => {
  const refSection = useRef(null);
  const [showSection, setShowSection] = useState(false);
  
  const {isIntersecting} = useIntersectionObserver(refSection);

  // Second condition prevents an infinite rendering loop.
  if (isIntersecting && !showSection) {
    setShowSection(true);
  }

  
  const items = [
    { id: 0, value: "CNC Punching" },
    { id: 1, value: "CNC Bending" },
    { id: 2, value: "CNC Shearing" },
    { id: 3, value: "Machining process Boring" },
    { id: 4, value: "Machining process Drilling" },
    { id: 5, value: "Machining process Knurling" },
    { id: 6, value: "Machining process Chamfering" },
    { id: 7, value: "Machining process Parting Off" },
    { id: 8, value: "Machining process Grooving" },
    { id: 9, value: "Machining process Forming" },
    { id: 10, value: "Machining process Tapper Turning" },
    { id: 11, value: "Machining process Threading" },
    { id: 12, value: "Machining process Reaming" },
    { id: 13, value: "Machining process Tapping" },
    { id: 14, value: "MiG Welding" },
    { id: 15, value: "Spot Welding" },
    { id: 16, value: "Arc Welding" },
    { id: 17, value: "Engineering Design" },
  ]

    return (
      <div className="Services">
        <Header/>
        <Container ref={refSection} maxWidth={false} style={styles.MainContainer}>
        
        <FadeIn showItem={showSection}>
        <span>
        <Typography variant="h3"
            component="div" style={styles.SubContainerHeadingAlt} sx={{ flexGrow: 1 }}>
            Services
        </Typography>
        <Typography variant="h6"
            component="div" style={styles.MainTextAlt} sx={{ flexGrow: 1 }}>
            NAHMA Precision Metal Products PLC offers a variety of general metal forming processes
            for your custom sheet metal products. If you need any sheet metal fabrication process,
            you can find it here.
        </Typography>
        <Typography variant="h6"
            component="div" style={styles.MainTextAlt} sx={{ flexGrow: 1 }}>
            You can also quickly fill in your order using the form below.
        </Typography>
        <OrderForm items={items} />

        </span>
        </FadeIn>
      </Container>
        <PunchingSection/>
        <BendingSection/>
        <ShearingSection/>
        <MachiningSection/>
        <WeldingSection/>
        <EngineeringDesignSection/>
        <Footer/>
        <BackToTop/>
      </div>
    )
  };

  export default Services;